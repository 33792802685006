@import "jit-refresh.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .bg-underline {
    padding-left: 0.1em;
    padding-right: 0.1em;
    background-image: linear-gradient(
      to bottom,
      transparent 60%,
      hsl(217, 94%, 93%) 60%,
      hsl(217, 94%, 93%) 85%,
      transparent 85%
    );
  }
}

@font-face {
  font-family: 'Inter var';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url('../fonts/inter/Inter-roman.var.woff2?v=3.19') format('woff2');
  font-named-instance: 'Regular';
}

@font-face {
  font-family: 'Inter var';
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url('../fonts/inter/Inter-italic.var.woff2?v=3.19') format('woff2');
  font-named-instance: 'Italic';
}

@font-face {
  font-family: 'Garamond';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url('../fonts/garamond/EBGaramond12-Regular.woff?v=0.016') format('woff');
  font-named-instance: 'Regular';
  size-adjust: 125%;
}
